import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/banner"
import ReviewerAbstractDetailTable from "../components/part/reviewerAbstractDetailTable"
import ReviewerAbstractDetailReview from "../components/part/reviewerAbstractDetailReview"
import AbstractDetailModal from "../components/part/abstractDetailModal"

const ReviewerAbstractDetailPage = () => (
  <Layout>
    <SEO title="Submission" />
    <Banner title="Abstract Submission"></Banner>
    <div className="content-wrap" style={{ marginTop: "32px" }}>
      <div className="container clearfix">
        <div className="row">
          <ReviewerAbstractDetailTable></ReviewerAbstractDetailTable>
          <ReviewerAbstractDetailReview></ReviewerAbstractDetailReview>
        </div>
        <AbstractDetailModal></AbstractDetailModal>
      </div>
    </div>
  </Layout>
)

export default ReviewerAbstractDetailPage
