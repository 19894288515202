import { Button } from "antd"
import { navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { DASHBOARD_REVIEWER } from "../../constants/routes"
import { Files } from "../../redux/api"

const ReviewerAbstractDetailReview = ({ state }) => {
  const [desc, setDesc] = useState("")
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      const rReview = await Files.review(state.file.detail.id)
      const data = rReview ? (await rReview.json()).reviews[0] : {}
      setDesc(data.description ? data.description : "")
      setDisabled(data.description ? true : false)
    }
    fetchData()
  }, [state.file.detail.id])

  const handleChangeDesc = e => {
    setDesc(e.target.value)
  }

  const handleSubmit = async () => {
    setLoading(true)
    const file_id = state.file.detail.id
    const description = desc
    await Files.reviewing({ file_id, description })
    setLoading(false)
    navigate(DASHBOARD_REVIEWER)
  }

  return (
    <div className="col-md-4 pd-reg">
      <div className="fancy-title top-md title-border">
        <h4>Review</h4>
      </div>
      <div className="form-widget">
        <div className="form-result" />
        <div className="form-group mb-4">
          <label htmlFor="approved">Feedback</label>
          <textarea
            className="form-control required"
            disabled={disabled}
            id="feedback"
            placeholder="Write your Feedback"
            rows={10}
            style={{ minHeight: "200px" }}
            value={desc}
            onChange={handleChangeDesc}
          />
        </div>
      </div>
      <div className="line line-sm mb-4" />
      <Button
        type={desc.length ? "primary" : null}
        className="mr-0 mb-3 w-100"
        onClick={handleSubmit}
        loading={loading}
        disabled={desc.length ? false : true}
      >
        Submit Review
      </Button>
      <button
        className="button button-circle button-border button-white button-reg mr-0 text-center"
        onClick={() => {
          navigate(DASHBOARD_REVIEWER)
        }}
      >
        CANCEL
      </button>
    </div>
  )
}

export default connect(state => ({ state }))(ReviewerAbstractDetailReview)
