import { navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { DASHBOARD_REVIEWER } from "../../constants/routes"
import { getBadgeStyling, renameStatus } from "../../utils/styling"
import { startCase } from "lodash"

const ReviewerAbstractDetailTable = ({ state }) => {
  const [form, setForm] = useState({})

  useEffect(() => {
    setForm(state.file.detail || {})
  }, [state.file.detail])

  return (
    <div className="col-md-8">
      <div className="row clearfix">
        <div className="col-lg-12">
          <div className="clearfix" id="abstract">
            <div className="row mb-1">
              <div className="col-md-6 mb-4">
                <button
                  className="button button-reg button-3d button-circle gradient-grey-orange mr-0 text-center mb-3 submit float-left"
                  onClick={() => navigate(DASHBOARD_REVIEWER)}
                >
                  Back to Dashboard
                </button>
              </div>
              {/* <div class="col-md-6">
                      <h3 class="float-right vertical-center mb-6"> Abstract Details </h3>
                    </div> */}
            </div>
            <table className="table table-bordered mb-5">
              <tbody>
                <tr>
                  <th className="w-25">Title</th>
                  <td className="w-75">{form.title}</td>
                </tr>
                <tr>
                  <th className="w-25">Status</th>
                  <td>
                    <span className={getBadgeStyling(form.status)}>
                      {renameStatus(form.status)}
                    </span>
                  </td>
                </tr>
                <tr>
                  <th className="w-25">Category</th>
                  <td className="w-75">{startCase(form.category)}</td>
                </tr>
                <tr>
                  <th className="w-25">Background</th>
                  <td className="w-75">{form.background}</td>
                </tr>
                <tr>
                  <th className="w-25">Methods</th>
                  <td className="w-75">{form.methods}</td>
                </tr>
                <tr>
                  <th className="w-25">Result</th>
                  <td className="w-75">{form.result}</td>
                </tr>
                <tr>
                  <th className="w-25">Conclusion</th>
                  <td className="w-75">{form.conclusion}</td>
                </tr>
                <tr>
                  <th className="w-25">Keywords</th>
                  <td className="w-75">{form.keywords}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(state => ({ state }))(ReviewerAbstractDetailTable)
